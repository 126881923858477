import VeeValidate from 'vee-validate';
import VueValidationDe from 'vee-validate/dist/locale/de';
import VueValidationFr from 'vee-validate/dist/locale/fr';
import VueFloatLabel from 'vue-float-label';
export default function ValidationPlugin(Vue, options) {
    Vue.use(VeeValidate, {
        classes: true,
        locale: window.locale,
        dictionary: {
            de: VueValidationDe,
            fr: VueValidationFr,
        },
        events: 'change',
    });
    Vue.use(VueFloatLabel);
    Vue.prototype.$displayErrors = function (validator, errors, scope) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                // Replace string dot notation with square brackets
                let name = key.replace(/\.(.+?)(?=\.|$)/g, (m, s) => `[${s}]`);
                let field = validator.fields.find({ name: name });
                if (!field) {
                    return;
                }
                field.setFlags({
                    invalid: true,
                    valid: false,
                    validated: true,
                });
                validator.errors.add({
                    field: field.name,
                    scope: scope,
                    msg: errors[key][0],
                });
            }
        }
    };
}
export class ValidationPluginOptions {
}
