import Vue from 'vue';
import ApiRequest from '@services/ApiRequestService';
import SimpleValidation from '@mixins/SimpleValidation';
export default Vue.extend({
    mixins: [SimpleValidation],
    data() {
        return {
            newsletterSubmissionLockProperty: false,
        };
    },
    methods: {
        newsletterSubmissionLock() {
            this.newsletterSubmissionLockProperty = true;
        },
        newsletterSubmissionUnlock() {
            this.newsletterSubmissionLockProperty = false;
        },
        isNewsletterSubmissionLocked() {
            return this.newsletterSubmissionLockProperty;
        },
        subscribeToNewsletter($event) {
            if (this.isNewsletterSubmissionLocked()) {
                return;
            }
            this.newsletterSubmissionLock();
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    $event.preventDefault();
                }
                ApiRequest.post('service/newsletter/subscribe', {
                    email: this.email,
                }).then((response) => {
                    const message = response.data.message;
                    if ('string' === typeof message) {
                        window.notifications.$flash(message, 'success');
                        this.newsletterSubmissionUnlock();
                        // this.email = null;
                        return;
                    }
                }, (error) => {
                    this.newsletterSubmissionUnlock();
                });
            });
        },
    },
});
