import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}
else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
let instance = axios.create({
    baseURL: '/' + window.locale + '/',
});
instance.interceptors.response.use(function (response) {
    let data = response.data;
    if (data.redirect) {
        window.location.href = data.redirect;
    }
    return response;
}, function (error) {
    let data = error.response.data;
    if (data.error) {
        for (let notification of data.error) {
            window.notifications.$flash(notification, 'error');
        }
    }
    if (data.message) {
        window.notifications.$flash(data.message, 'error');
    }
    if (data.errors && data.errors.file) {
        for (let i = 0; i < data.errors.file.length; i++) {
            window.notifications.$flash(data.errors.file[i], 'error');
        }
    }
    return Promise.reject(error);
});
export default instance;
