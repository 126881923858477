import Vue from 'vue';
import forEach from 'lodash/forEach';
import VueDataScooper from 'vue-data-scooper';
import Validation from '@plugins/ValidationPlugin';
Vue.use(VueDataScooper);
Vue.use(Validation);
export default Vue.extend({
    data() {
        return {};
    },
    beforeMount() {
        this.$validator.extend('not_empty', {
            getMessage: (field) => this.$validator.dictionary.getMessage(window.locale.toString(), 'required', [field]),
            validate: (value) => {
                return ((!value.editor && value !== '') ||
                    (value.editor && value.editor._.data !== ''));
            },
        });
    },
    mounted() {
        let errors = this.formErrors ? JSON.parse(this.formErrors) : {};
        this.$displayErrors(this.$validator, errors);
    },
    methods: {
        submit(e) {
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    e.preventDefault();
                }
            });
        },
        PromptErrorMessage() {
            let errorMessages = [];
            forEach(this.errors.items, (message) => {
                errorMessages.push(message.msg);
            });
            window.notifications.$flash(errorMessages.join('<br>'), 'error');
        },
        isValid(name) {
            let field = this.$validator.fields.find({ name: name });
            return field && field.flags.validated && field.flags.valid;
        },
    },
});
