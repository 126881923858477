import Vue from 'vue';
import LazyLoad from 'vanilla-lazyload';
import { BCollapse, VBToggle } from 'bootstrap-vue';
import Newsletter from '@mixins/Newsletter';
new Vue({
    el: '#footer',
    components: {
        'b-collapse': BCollapse,
    },
    directives: {
        'b-toggle': VBToggle,
    },
    mixins: [Newsletter],
    mounted() {
        new LazyLoad({
            elements_selector: '.lazy',
        });
    },
});
